import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/feature.css";

function Feature({ Icon, Title, Description }) {
    return (
        <div className="feature">
            <FontAwesomeIcon className="icon" icon={Icon} />
            <h3>{Title}</h3>
            <div className="description">{Description}</div>
        </div>
    );
}

export default Feature;