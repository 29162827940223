import React, { useState, useEffect } from "react";
import "../styles/mailModal.css";

function MailModal({ closeModal }) {
    const [email, setEmail] = useState("");
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    const emailInputChanged = (event) => {
        setEmail(event.target.value);
    };

    const handleCloseModal = () => {
        setIsVisible(false);
        setTimeout(closeModal, 500);
    };

    const submitEmail = async () => {
        const response = await fetch("https://awaitfunction.xyz/api/ml", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: email
            })
        });
        if (response.status !== 200) console.error("Unable to Save Email:", response.status);
        
        handleCloseModal();
    };

    return (
        <div className={`mail-modal ${isVisible ? "fade-in" : ""}`}>
            <div className="background" onClick={handleCloseModal}></div>
            <div className="modal-content">
                <button className="close-btn" onClick={handleCloseModal}>x</button>
                <h1>Want To Stay Updated?</h1>
                <p>
                    If you join our Mailing List, we will email you about important
                    changes to our service.
                </p>
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={emailInputChanged}
                />
                <button className="subscribe-btn" onClick={submitEmail}>Subscribe</button>
            </div>
        </div>
    );
}

export default MailModal;