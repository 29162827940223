import React, { useState, useEffect } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import "../styles/samplesModal.css";

function SamplesModal({ closeModal }) {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    const handleCloseModal = () => {
        setIsVisible(false);
        setTimeout(closeModal, 500);
    };

    const codeSample1 = `local HttpService = game:GetService("HttpService")

HttpService:PostAsync("https://discordapp.com/api/webhooks/WEBHOOK_ID/WEBHOOK_TOKEN", 
    HttpService:JSONEncode({
        content = "Hello, world!",
    })
);`;

    const codeSample2 = `local HttpService = game:GetService("HttpService")

HttpService:PostAsync("https://awaitfunction.xyz/api/webhooks/WEBHOOK_ID/WEBHOOK_TOKEN", 
    HttpService:JSONEncode({
        content = "Hello, world!",
    })
);`;

    return (
        <div className={`samples-modal ${isVisible ? "fade-in" : ""}`}>
            <div className="background" onClick={handleCloseModal}></div>
            <div className="modal-content">
                <button className="close-btn" onClick={handleCloseModal}>x</button>
                <h1>Code Samples</h1>
                <p>You're probably starting with some code like this:</p>
                
                <SyntaxHighlighter language="lua" style={oneDark}>
                    {codeSample1}
                </SyntaxHighlighter>

                <p>It's very simple to convert this. Just swap out <span className="theme-highlight">discordapp.com</span> with <span className="theme-highlight">awaitfunction.xyz</span>!</p>

                <SyntaxHighlighter language="lua" style={oneDark}>
                    {codeSample2}
                </SyntaxHighlighter>

                <p>In no time at all you will be sending requests from Roblox to Discord.</p>
            </div>
        </div>
    );
}

export default SamplesModal;