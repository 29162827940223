import React from "react";
import Home from "./pages/home.js";
import "./styles/app.css";

function App() {
    return (
        <div className="app">
            <Home />
        </div>
    );
}

export default App;