import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUpRightFromSquare,
    faChevronRight,
    faHammer,
    faShieldAlt,
    faSyncAlt,
    faBolt,
} from "@fortawesome/free-solid-svg-icons";
import CountUp from "react-countup";
import FeatureComponent from "../components/feature";
import MailModal from "../components/mailModal";
import SamplesModal from "../components/samplesModal";
import pageIcon from "../icon.png";
import "../styles/home.css";

function Home() {
    const [outputURL, setOutputURL] = useState("Output URL");
    const [totalTokens, setTotalTokens] = useState(0);
    const [totalRequests, setTotalRequests] = useState(0);
    const [showMailModal, setShowMailModal] = useState(false);
    const [showSamplesModal, setShowSamplesModal] = useState(false);

    const inputURLChanged = (event) => {
        const input = event.target.value;
        if (input.includes("discordapp.com")) {
            const newOutput = input.replace("discordapp.com", "awaitfunction.xyz");
            setOutputURL(newOutput);
        } else {
            setOutputURL("Output URL");
        }
    };

    const mailingListPopup = () => {
        setTimeout(() => {
            if (localStorage.getItem("mailingListComplete") === "true") return;
            setShowMailModal(true);
        }, 15000);
    };

    useEffect(() => {
        mailingListPopup();

        const fetchData = async () => {
            try {
                const response = await fetch("https://awaitfunction.xyz/api/data");
                if (response.status !== 200) {
                    console.error("Unable to Fetch Data:", response.status);
                }
                const data = await response.json();
                
                setTotalTokens(data.tokens);
                setTotalRequests(data.requests);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [totalRequests, totalTokens]);

    const closeMailModal = () => {
        localStorage.setItem("mailingListComplete", "true");
        setShowMailModal(false);
    };

    const closeSamplesModal = () => {
        setShowSamplesModal(false);
    };

    const openSamplesModal = () => {
        setShowSamplesModal(true);
    };

    const features = [
        {
            icon: faHammer,
            title: "Easy to Setup",
            description: (
                <span>
                    Simply replace <span className="theme-highlight">discordapp.com</span>{" "}
                    with <span className="theme-highlight">awaitfunction.xyz</span> in
                    your Webhook URL. That's it!
                </span>
            ),
        },
        {
            icon: faShieldAlt,
            title: "Secure and Private",
            description: (
                <span>
                    All requests are encrypted end-to-end, ensuring your webhook data
                    stays safe.
                </span>
            ),
        },
        {
            icon: faSyncAlt,
            title: "Reliable",
            description: (
                <span>
                    We have proudly served{" "}
                    <span className="theme-highlight">
                        <CountUp
                            end={totalTokens}
                            duration={3}
                            style={{ color: "#6370d3", fontWeight: 600 }}
                        />
                    </span>{" "}
                    unique Webhook URLs, and handled{" "}
                    <span className="theme-highlight">
                        <CountUp
                            end={totalRequests}
                            duration={3}
                            style={{ color: "#6370d3", fontWeight: 600 }}
                        />
                    </span>{" "}
                    requests to date.
                </span>
            ),
        },
        {
            icon: faBolt,
            title: "Ratelimit Handling",
            description: (
                <span>
                    We allow each Webhook URL to send ten requests per second. Any
                    additional requests are added to a queue and sent later on!
                </span>
            ),
        },
    ];

    return (
        <>
            <div className="landing">
                <img className="icon" alt="Icon" src={pageIcon} />
                <h1>awaitfunction.xyz</h1>
                <p>Dedicated Roblox Webhook Proxy</p>

                <div className="url-conversion">
                    <input defaultValue="Input URL" onChange={inputURLChanged} />
                    <p>
                        <FontAwesomeIcon icon={faChevronRight} className="arrow-right" />
                    </p>
                    <input readOnly={true} value={outputURL} />
                </div>

                <div className="helpful-buttons">
                    <button onClick={openSamplesModal}>
                        <p>Code Samples</p>
                    </button>
                    <a className="gray-button" href="https://discord.gg/kWHXmkfKFz">
                        <p>
                            <FontAwesomeIcon icon={faUpRightFromSquare} /> Discord Server
                        </p>
                    </a>
                </div>
            </div>

            <div className="features">
                <h2>Why use our Proxy?</h2>
                <div className="container">
                    {features.map((feature, index) => (
                        <FeatureComponent
                            key={index}
                            Icon={feature.icon}
                            Title={feature.title}
                            Description={feature.description}
                        />
                    ))}
                </div>
            </div>

            {showSamplesModal && <SamplesModal closeModal={closeSamplesModal} />}
            {showMailModal && <MailModal closeModal={closeMailModal} />}

            <div className="footer">
                <p>© Copyright 2024</p>
            </div>
        </>
    );
}

export default Home;